import React, { useEffect, useState } from 'react';
import { Container, TextField, Typography, Paper, Box } from '@mui/material';

function App() {
  const [authCode, setAuthCode] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    setAuthCode(code);

    const parentOrigin = queryParams.get("state");

    const messageContent = {
      authCode: code,
    };

    window.opener?.postMessage(messageContent, parentOrigin);

    const handleMessageFromParent = (event) => {
      if (event.data.type === "confirmation" && event.data.message === "authCodeReceived") {
        console.log("Received confirmation from parent:", event.data.message);
        window.close();
      }
    };

    window.addEventListener("message", handleMessageFromParent);

    return () => {
      window.removeEventListener("message", handleMessageFromParent);
    }
  }, []);

  return (
    <Container component={Paper} maxWidth="md" style={{ padding: '2rem', marginTop: '3rem' }}>
      <Typography variant="h4" gutterBottom>
        HubSpot Installation Code
      </Typography>
      <Typography paragraph>
        Copy the code below and follow the instructions on the CXM Integration.
      </Typography>
      <Box mt={2}>
        <TextField
          fullWidth
          label="Auth Code"
          value={authCode}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </Container>
  );
}

export default App;
